<template>
	<b-container fluid>
		<b-row>
			<b-col lg="12">
				<iq-card body-class="p-0">
					<template v-slot:body>
						<div class="iq-edit-list">
							<tab-nav :pills="true" class="iq-edit-profile d-flex">
								<tab-nav-items
									class="col-md-4 p-0"
									:active="true"
									href="#personal-information"
									title="Información Personal"
								/>
								<tab-nav-items
									class="col-md-4 p-0"
									:active="false"
									href="#extra-information"
									title="Historia Clinica"
								/>
								<tab-nav-items
									class="col-md-4 p-0"
									:active="false"
									href="#extra-information-2"
									title="Historial Fisioterapeutico"
								/>
							</tab-nav>
						</div>
					</template>
				</iq-card>
			</b-col>
			<b-col lg="12">
				<div class="iq-edit-list-data">
					<tab-content id="pills-tabContent-2">
						<tab-content-item :active="true" id="personal-information">
							<iq-card>
								<template v-slot:headerTitle>
									<h4 class="card-title">Información Personal</h4>
								</template>
								<template v-slot:body>
									<ValidationObserver ref="form" v-slot="{ handleSubmit }">
										<b-form @submit.stop.prevent="handleSubmit(onSubmit)">
											<b-form-group class="row align-items-center">
												<b-col md="12">
													<div class="profile-img-edit">
														<b-img
															:src="userForm.photo"
															class="profile-pic height-150 width-150"
															alt="profile-pic"
														/>
														<input type="hidden" v-model="userForm.photo" />
														<div class="p-image">
															<div class="position-relative">
																<b-form-file
																	class="h-100 position-absolute"
																	accept="image/*"
																	style="opacity: 0"
																	@change="previewImagesFisio"
																></b-form-file>
																<i class="ri-pencil-line upload-button"></i>
															</div>
														</div>
													</div>
												</b-col>
											</b-form-group>
											<b-row align-v="center">
												<b-form-group
													class="col-md-4"
													label="Primer Apellido:"
													label-for="first_last_name"
												>
													<ValidationProvider
														name="Primer Apellido"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="userForm.first_last_name"
															type="text"
															placeholder="Primer Apellido"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group
													class="col-md-4"
													label="Segundo Apelllido:"
													label-for="second_last_name"
												>
													<ValidationProvider
														name="Segundo Apelllido"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="userForm.second_last_name"
															type="text"
															placeholder="Segundo Apelllido"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-4" label="Nombres:" label-for="name">
													<ValidationProvider
														name="Nombres"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="userForm.name"
															type="text"
															placeholder="Nombres"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group
													class="col-md-6"
													label-for="document_type"
													label="Tipo de Documento:"
												>
													<b-form-select
														v-model="userForm.document_type"
														plain
														:options="['CEDULA', 'PASAPORTE']"
														id="document_type"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
													class="col-md-6"
													label="Número de Documento:"
													label-for="document_number"
												>
													<ValidationProvider
														name="Número de Documento"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="userForm.document_number"
															type="text"
															placeholder="Número de Documento"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group
													class="col-md-6"
													label="Correo Electrónico:"
													label-for="email"
												>
													<ValidationProvider
														name="Correo Electrónico"
														rules="required|email"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="userForm.email"
															type="email"
															placeholder="Correo Electrónico"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group
													class="col-md-6"
													label="Contraseña:"
													label-for="password"
												>
													<ValidationProvider
														name="Contraseña"
														rules=""
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="userForm.password"
															type="password"
															placeholder="Contraseña"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group
													class="col-md-4"
													label-for="civil_status"
													label="Estado Civil:"
												>
													<b-form-select
														v-model="userForm.civil_status"
														plain
														:options="[
															'SOLTERO',
															'CASADO',
															'VIUDO',
															'DIVORCIADO',
															'UNION LIBRE',
														]"
														id="civil_status"
													>
													</b-form-select>
												</b-form-group>

												<b-form-group
													class="col-md-4"
													label="Género:"
													label-class="d-block"
												>
													<b-form-radio
														inline
														v-model="userForm.gender"
														value="MASCULINO"
													>
														MASCULINO
													</b-form-radio>
													<b-form-radio inline v-model="userForm.gender" value="FEMENINO">
														FEMENINO
													</b-form-radio>
												</b-form-group>

												<b-form-group
													class="col-md-4"
													label="Fecha de Nacimiento:"
													label-for="birthdate"
												>
													<ValidationProvider
														name="Fecha de Nacimiento"
														rules="required"
														v-slot="{ errors }"
													>
														<input
															class="form-control"
															type="date"
															v-model="userForm.birthdate"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														/>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group
													class="col-md-6"
													label="Teléfono:"
													label-for="phone"
												>
													<ValidationProvider
														name="Teléfono"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="userForm.phone"
															type="text"
															placeholder="Teléfono"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group
													class="col-md-6"
													label="Dirección:"
													label-for="address"
												>
													<ValidationProvider
														name="Dirección"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="userForm.address"
															type="text"
															placeholder="Dirección"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group
													class="col-md-6"
													label-for="province"
													label="Provincia:"
												>
													<b-form-select
														v-model="selectedProvince"
														plain
														:options="provinces"
														id="province"
														@change="getCantones"
														value-field="id"
														text-field="name"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group class="col-md-6" label-for="city" label="Ciudad:">
													<b-form-select
														v-model="userForm.city"
														plain
														:options="cantones"
														id="city"
														value-field="canton"
														text-field="canton"
													>
													</b-form-select>
												</b-form-group>
												<div class="col-md-12 w-100">
													<b-button type="submit" variant="primary" class="mr-2">
														Guardar
													</b-button>
												</div>
											</b-row>
										</b-form>
									</ValidationObserver>
								</template>
							</iq-card>
						</tab-content-item>
						<tab-content-item :active="false" id="extra-information">
							<iq-card>
								<template v-slot:headerTitle>
									<h4 class="card-title">Historia Clinica Medica</h4>
								</template>
								<template v-slot:body>
									<ValidationObserver ref="form" v-slot="{ handleSubmit }">
										<b-form @submit.stop.prevent="handleSubmit(onSubmitExtraData)">
											<b-row>
												<b-form-group class="col-md-6" label="Institucion del Sistema:">
													<ValidationProvider name="Institucion del Sistema" rules="required" v-slot="{ errors }">
														<b-form-input
															v-model="userExtraData.institucion"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="RUC:">
													<ValidationProvider name="RUC" rules="required" v-slot="{ errors }">
														<b-form-input
															v-model="userExtraData.ruc"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="CIU:">
													<ValidationProvider name="CIU" rules="required" v-slot="{ errors }">
														<b-form-input
															v-model="userExtraData.ciu"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Establecimiento de Salud:">
													<ValidationProvider name="Establecimiento de Salud" rules="required" v-slot="{ errors }">
														<b-form-input
															v-model="userExtraData.establecimiento"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-12" label="Motivo de Consulta:">
													<ValidationProvider name="Motivo de Consulta:" rules="required" v-slot="{ errors }">
														<b-form-textarea
															v-model="userExtraData.reason_consultation"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-textarea>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<div class="col-md-12">
													<hr />
												</div>
												<div class="col-md-12">
													<p>ANTESCEDENTES PERSONALES</p>
												</div>
												<b-form-group class="col-md-6" label="Clinico:">
													<ValidationProvider name="Clinico:" rules="required" v-slot="{ errors }">
														<b-form-input
															v-model="userExtraData.clinical_history"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="QUIRURGICOS:">
													<ValidationProvider name="QUIRURGICOS:" rules="required" v-slot="{ errors }">
														<b-form-input
															v-model="userExtraData.surgical_history"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<div class="col-md-12">
													<hr />
												</div>
												<div class="col-md-12">
													<p>HABITOS Y ESTILO DE VIDA</p>
												</div>
												<b-form-group class="col-md-6" label="TABACO:">
													<ValidationProvider name="TABACO:" rules="required" v-slot="{ errors }">
														<b-form-input
															v-model="userExtraData.tabaco"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="ALCOHOL:">
													<ValidationProvider name="ALCOHOL:" rules="required" v-slot="{ errors }">
														<b-form-input
															v-model="userExtraData.alcohol"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="ACTVIDAD FISICA:">
													<ValidationProvider name="ACTIVIDAD FISICA:" rules="required" v-slot="{ errors }">
														<b-form-input
															v-model="userExtraData.actividad"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="MEDICACION:">
													<ValidationProvider name="MEDICACION:" rules="required" v-slot="{ errors }">
														<b-form-input
															v-model="userExtraData.medicacion"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<div class="col-md-12">
													<hr />
												</div>
												<b-form-group class="col-md-12" label="Antecedentes Familiares:">
													<ValidationProvider name="Antecedentes Familiares:" rules="required" v-slot="{ errors }">
														<b-form-textarea
															v-model="userExtraData.family_background"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-textarea>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-12" label="Enfermedad Actual:">
													<ValidationProvider name="Enfermedad Actual:" rules="required" v-slot="{ errors }">
														<b-form-textarea
															v-model="userExtraData.current_illness"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-textarea>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<!-- <b-form-group class="col-md-6" label="Diagnósticos:">
													<ValidationProvider
														name="Diagnósticos"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="userExtraData.diagnoses"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Tipo de Sangre:">
													<ValidationProvider
														name="Tipo de Sangre"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-select
															v-model="userExtraData.blood_type"
															plain
															:options="['A+', 'O+', 'B+', 'AB+', 'A-', 'O-', 'B-', 'AB-']"
															id="blood_type"
														>
														</b-form-select>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group> -->
												<div class="col-md-12">
													<hr />
												</div>
												<b-form-group class="col-md-6" label="Revision de Organos y Sistemas:">
													<ValidationProvider name="Revision de Organos y Sistemas" rules="required" v-slot="{ errors }">
														<v-select
															:options="[
																'PIEL - ANEXOS',
																'ORGANOS DE LOS SENTIDOS',
																'RESPIRATORIO',
																'CARDIO- VASCULAR',
																'DIGESTIVO',
																'GENITO- URINARIO',
																'MUSCULO- ESQUELETICO',
																'ENDOCRINO',
																'HEMO- LINFATIVO',
																'NERVISOS',
															]"
															v-model="userExtraData.organ_check"
															:class="errors.length > 0 ? ' is-invalid' : ''"
															multiple
														/>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Observaciones Adicionales:">
													<ValidationProvider
														name="Observaciones"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="userExtraData.organ_description"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<div class="col-md-12">
													<hr />
												</div>
												<div class="col-md-12">
													<p>Examen Fisio Regional</p>
												</div>
												<b-form-group
													class="col-md-4"
													label-for="Piel"
													label="Piel:"
												>
													<b-form-select
														v-model="userExtraData.piel"
														plain
														:options='["Cicatrices", "Tatuajes", "Piel y Faneras", "Ninguno"]'
														id="fuerza"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
													class="col-md-4"
													label-for="Ojos"
													label="Ojos:"
												>
													<b-form-select
														v-model="userExtraData.ojos"
														plain
														:options='["Párpados", "Conjuntivas", "Pupilas", "Córnea", "Motilidad", "Ninguno"]'
														id="fuerza"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
													class="col-md-4"
													label-for="Oido"
													label="Oido:"
												>
													<b-form-select
														v-model="userExtraData.oido"
														plain
														:options='["C. auditivo externo", "Pabellón", "Tímpanos", "Ninguno"]'
														id="fuerza"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
													class="col-md-4"
													label-for="Faringe"
													label="Faringe:"
												>
													<b-form-select
														v-model="userExtraData.faringe"
														plain
														:options='["Labios", "Lengua", "Faringe", "Amígdalas", "Dentadura", "Ninguno"]'
														id="fuerza"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
													class="col-md-4"
													label-for="Nariz"
													label="Nariz:"
												>
													<b-form-select
														v-model="userExtraData.nariz"
														plain
														:options='["Tabique", "Cornetes", "Mucosas", "Senos paranasales", "Ninguno"]'
														id="fuerza"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
													class="col-md-4"
													label-for="Cuello"
													label="Cuello:"
												>
													<b-form-select
														v-model="userExtraData.cuello"
														plain
														:options='["Tiroides / masas", "Cornetes", "Movilidad", "Ninguno"]'
														id="fuerza"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
													class="col-md-4"
													label-for="Torax"
													label="Torax:"
												>
													<b-form-select
														v-model="userExtraData.torax"
														plain
														:options='["Mamas", "Corazón", "Pulmones", "Parrilla Costal", "Ninguno"]'
														id="fuerza"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
													class="col-md-4"
													label-for="Abdomen"
													label="Abdomen:"
												>
													<b-form-select
														v-model="userExtraData.abdomen"
														plain
														:options='["Vísceras", "Pared abdominal", "Ninguno"]'
														id="fuerza"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
													class="col-md-4"
													label-for="Columna"
													label="Columna:"
												>
													<b-form-select
														v-model="userExtraData.columna"
														plain
														:options='["Flexibilidad", "Desviacion", "Dolor", "Ninguno"]'
														id="fuerza"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
													class="col-md-4"
													label-for="Pelvis"
													label="Pelvis:"
												>
													<b-form-select
														v-model="userExtraData.pelvis"
														plain
														:options='["Pelvis", "Genitales", "Ninguno"]'
														id="fuerza"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
													class="col-md-4"
													label-for="Extremidades"
													label="Extremidades:"
												>
													<b-form-select
														v-model="userExtraData.extremidades"
														plain
														:options='["Vascular", "Miembros superiores", "Miembros inferiores", "Ninguno"]'
														id="fuerza"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
													class="col-md-4"
													label-for="Neurologico"
													label="Neurologico:"
												>
													<b-form-select
														v-model="userExtraData.neurologico"
														plain
														:options='["Fuerza", "Sensibilidad", "Marcha", "Reflejos", "Ninguno"]'
														id="fuerza"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group class="col-md-12" label="Observaciones:">
													<ValidationProvider
														name="Observaciones Fisico"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="userExtraData.regions_observations"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<!-- <div class="col-md-12">
													<p>Antecedentes Personales</p>
												</div>
												<b-form-group class="col-md-4" label="Problemas de salud pasados y actuales:">
													<ValidationProvider
														name="Problemas de salud pasados y actuales"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="userExtraData.health_problems"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-4" label="Condiciones crónicas o enfermedades:">
													<ValidationProvider
														name="Condiciones crónicas o enfermedades"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="userExtraData.chronic_conditions_diseases"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-4" label="Lesiones previas o cirugías:">
													<ValidationProvider
														name="Lesiones previas o cirugías"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="userExtraData.previous_injuries_surgeries"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-4" label="Alergias conocidas:">
													<ValidationProvider
														name="Alergias conocidas"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="userExtraData.known_allergies"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group
													class="col-md-8"
													label="Medicamentos que el paciente esté tomando actualmente:"
												>
													<ValidationProvider
														name="Medicamentos que el paciente esté tomando actualmente"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="userExtraData.medications_currently_taking"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group> -->
												<div class="col-md-12">
													<hr />
												</div>
												<div class="col-md-12">
													<p>Resultado De Examenes (Imagen, Laboratorio y Otros)</p>
												</div>
												<table class="col-md-12 table" style="padding: 0px 15px">
													<thead class="text-center">
														<tr>
															<th>Nombre</th>
															<th>Examen</th>
															<th>Fecha</th>
															<th>Resultado</th>
															<th>-</th>
														</tr>
													</thead>
													<tbody>
														<tr v-for="item in results">
															<td>{{ item.name }}</td>
															<td>
																<div class="row">
																	<div v-for="image in item.images" class="col-6">
																		<img :src="image" alt="" class="mw-100" />
																	</div>
																</div>
															</td>
															<td>{{ item.date }}</td>
															<td>{{ item.result }}</td>
															<td>
																<button class="btn btn-warning" @click="() => handleDeleteResult(item.result)">X</button>
															</td>
														</tr>
														<tr>
															<td>
																<b-form-input 
																	v-model="result.name"
																></b-form-input>
															</td>
															<td style="max-width: 300px;">
																<b-form-group class="col-md-12" label="Imagenes:">
																	<ValidationProvider
																		name="Imagenes"
																		v-slot="{ errors }"
																	>
																		<input
																			type="file"
																			multiple
																			accept="image/*"
																			@change="previewImages"
																			:class="errors.length > 0 ? ' is-invalid' : 'mb-2'"
																		/>
																		<div class="invalid-feedback">
																			<span>{{ errors[0] }}</span>
																		</div>
																	</ValidationProvider>
																	<div class="row" v-if="images.length > 0">
																		<div v-for="(image, index) in images " :key="index" class="col-12">
																			<img :src="image" alt="" class="mw-100" />
																		</div>
																	</div>
																</b-form-group>
																<!-- <b-form-input 
																	type="text"
																	v-model="result.examen"
																></b-form-input> -->
																<!-- <input 
																	type="file"
																></input> -->
															</td>
															<td>
																<b-form-input 
																	type="date"
																	v-model="result.date"
																></b-form-input>
															</td>
															<td>
																<b-form-input 
																	v-model="result.result"
																></b-form-input>
															</td>
															<td>
																<button @click="handleAddResult" class="btn btn-primary">Agregar</button>
															</td>
														</tr>
													</tbody>
												</table>
												<b-form-group class="col-md-12" label="Observaciones de Resultados:">
													<ValidationProvider
														name="Observaciones"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="userExtraData.results_description"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>

												<div class="col-md-12">
													<p>K. DIAGNÓSTICO</p>
												</div>
												<table class="col-md-12 table" style="padding: 0px 15px">
													<thead class="text-center">
														<tr>
															<th>Descripción</th>
															<th>CIE</th>
															<th>PRE/DEF</th>
															<th>-</th>
														</tr>
													</thead>
													<tbody>
														<tr v-for="item in diagnosses">
															<td>{{ item.description }}</td>
															<td>{{ item.cie }}</td>
															<td>{{ item.pre }}</td>
															<td>
																<button class="btn btn-warning" @click="() => handleDeleteDiagnosis(item.cie)">X</button>
															</td>
														</tr>
														<tr>
															<td>
																<b-form-input 
																	v-model="diagnosis.description"
																></b-form-input>
																<!-- <input 
																	type="file"
																></input> -->
															</td>
															<td>
																<b-form-input 
																	v-model="diagnosis.cie"
																></b-form-input>
															</td>
															<td>
																<b-form-group label-class="d-block">
																	<b-form-radio
																		inline
																		v-model="diagnosis.pre"
																		value="pre"
																	>
																		PRE
																	</b-form-radio>
																	<b-form-radio 
																		inline 
																		v-model="diagnosis.pre" 
																		value="def"
																	>
																		DEF
																	</b-form-radio>
																</b-form-group>
															</td>
															<td>
																<button @click="handleAddDiagnosis" class="btn btn-primary">Agregar</button>
															</td>
														</tr>
													</tbody>
												</table>
												<b-form-group class="col-md-12" label="Recomendaciones Y/O Tratamientos:">
													<ValidationProvider
														name="Recomendaciones"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-textarea
															v-model="userExtraData.treatment"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-textarea>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<!-- <b-form-group
													class="col-md-6"
													label="Terapias o tratamientos de fisioterapia:"
												>
													<ValidationProvider
														name="Terapias o tratamientos de fisioterapia"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="userExtraData.therapies"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Hábitos:">
													<ValidationProvider name="Hábitos" rules="required" v-slot="{ errors }">
														<v-select
															:options="[
																'ALCOHOL',
																'CIGARRILLOS',
																'EJERCICIOS',
																'BUENA ALIMENTACIÓN',
																'MALA ALIMENTACIÓN',
																'CAFEINA',
															]"
															v-model="userExtraData.habits"
															:class="errors.length > 0 ? ' is-invalid' : ''"
															multiple
														/>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group
													class="col-md-6"
													label="Información sobre estilo de vida y actividad fisica:"
												>
													<ValidationProvider
														name="Información sobre estilo de vida y actividad fisica"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="userExtraData.lifestyle"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Hábitos alimentarios relevantes:">
													<ValidationProvider
														name="Hábitos alimentarios relevantes"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="userExtraData.relevant_habits"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Hábitos de sueño:">
													<ValidationProvider
														name="Hábitos de sueño"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="userExtraData.sleep_habits"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Condición de salud mental:">
													<ValidationProvider
														name="Condición de salud mental"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="userExtraData.mental_health_condition"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group> -->
												<b-form-group class="col-md-3" label="TEMPERATURA:">
													<ValidationProvider name="TEMPERATURA" rules="required" v-slot="{ errors }">
														<b-form-input
															v-model="userExtraData.temperature"
															type="number"
															step=".01"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-3" label="FRECUENCIA CARDIACA:">
													<ValidationProvider name="FRECUENCIA CARDIACA" rules="required" v-slot="{ errors }">
														<b-form-input
															v-model="userExtraData.heart_rate"
															type="number"
															step=".01"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-3" label="SATURACIÓN DE OXÍGENO:">
													<ValidationProvider name="SATURACIÓN DE OXÍGENO" rules="required" v-slot="{ errors }">
														<b-form-input
															v-model="userExtraData.oxigen"
															type="number"
															step=".01"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-3" label="FRECUENCIA RESPIRATORIA:">
													<ValidationProvider name="FRECUENCIA RESPIRATORIA" rules="required" v-slot="{ errors }">
														<b-form-input
															v-model="userExtraData.frequency"
															type="number"
															step=".01"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-3" label="Peso (kg):">
													<ValidationProvider name="Peso" rules="required" v-slot="{ errors }">
														<b-form-input
															v-model="userExtraData.weight"
															type="number"
															step=".01"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-3" label="Estatura (cm.):">
													<ValidationProvider name="Estatura" rules="required" v-slot="{ errors }">
														<b-form-input
															v-model="userExtraData.height"
															type="number"
															step=".01"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-3" label="Presión Arterial Sistólica:">
													<ValidationProvider
														name="Presión Arterial"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="userExtraData.systolic_pressure"
															type="number"
															step=".01"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-3" label="Presión Arterial Diastólica:">
													<ValidationProvider
														name="Presión Arterial"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="userExtraData.diastolic_pressure"
															type="number"
															step=".01"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group
													class="col-md-12"
													label-for="Personal de Salud"
													label="Personal de Salud:"
												>
													<b-form-select
														v-model="userExtraData.doctor_id"
														plain
														:options='doctors'
														id="salud"
													>
													</b-form-select>
												</b-form-group>
											</b-row>
											<b-button type="submit" variant="primary" class="mr-2">
												Guardar
											</b-button>
											<b-button type="reset" variant="none" class="iq-bg-danger">
												Cancelar
											</b-button>
										</b-form>
									</ValidationObserver>
								</template>
							</iq-card>
						</tab-content-item>
						<tab-content-item :active="false" id="extra-information-2">
							<iq-card>
								<template v-slot:headerTitle>
									<h4 class="card-title">Historia Clinica Fisioterapeutico</h4>
								</template>
								<template v-slot:body>
									<ValidationObserver ref="form" v-slot="{ handleSubmit }">
										<b-form @submit.stop.prevent="handleSubmit(onSubmitFisio)">
											<b-row>
												<div class="col-md-12">
													<p>SIGNOS VITALES:</p>
												</div>
												<b-form-group class="col-md-6" label="Presión Arterial:">
													<ValidationProvider
														name="Presión Arterial:"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="data.blood_pressure"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Pulso:">
													<ValidationProvider
														name="Pulso:"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="data.pulse"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Frecuencia Respiratoria:">
													<ValidationProvider
														name="Frecuencia Respiratoria"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="data.breathing_frequency"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Temperatura:">
													<ValidationProvider
														name="Temperatura"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="data.temperature"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<hr />
												<div class="col-md-12">
													<p>ANTECEDENTES HEREDO FAMILIARES:</p>
												</div>
												<b-form-group class="col-md-6" label="Diabetes:">
													<ValidationProvider
														name="Diabetes"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="data.diabetes"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="HTA:">
													<ValidationProvider
														name="HTA"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="data.hta"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Enf. Reumal:">
													<ValidationProvider
														name="Enf. Reumal"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="data.reumal"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Cirugias:">
													<ValidationProvider
														name="Cirugias"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="data.cirugias"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Accidentes:">
													<ValidationProvider
														name="Accidentes"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="data.accidentes"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Alergias:">
													<ValidationProvider
														name="Alergias"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="data.alergias"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Fracturas:">
													<ValidationProvider
														name="Fracturas"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="data.fracturas"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-12" label="Otros:">
													<ValidationProvider
														name="Otros"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-textarea
															v-model="data.otros_antecedentes"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-textarea>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-12" label="Motivo de Consulta:">
													<ValidationProvider
														name="Motivo de Consulta"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-textarea
															v-model="data.reason_consultation"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														>
														</b-form-textarea>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-12" label="PATOLOGÍA(ENFERMEDAD ACTUAL):">
													<ValidationProvider
														name="PATOLOGÍA(ENFERMEDAD ACTUAL)"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-textarea
															v-model="data.pathology"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														>
														</b-form-textarea>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Exploración fisica:">
													<ValidationProvider
														name="Exploración fisica"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="data.exploracion_fisica"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Vista Anatómica:">
													<ValidationProvider
														name="Vista Anatómica"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="data.vista_anatomica"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Vista Anterior:">
													<ValidationProvider
														name="Vista Anterior"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="data.vista_anterior"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Vista Posterior:">
													<ValidationProvider
														name="Vista Posterior"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="data.vista_posterior"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Vista Lateral:">
													<ValidationProvider
														name="Vista Lateral"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="data.vista_lateral"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Imagenes:">
													<ValidationProvider
														name="Imagenes"
														v-slot="{ errors }"
													>
														<input
															type="file"
															multiple
															accept="image/*"
															@change="previewImages"
															:class="errors.length > 0 ? ' is-invalid' : 'mb-2'"
														/>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
													<div class="row" v-if="images.length > 0">
														<div v-for="(image, index) in images " :key="index" class="col-4">
															<img :src="image" alt="" class="mw-100" />
														</div>
													</div>
												</b-form-group>
												<div class="col-md-12">
													<p>Palpación:</p>
												</div>
												<b-form-group class="col-md-6" label="Enf.Reumatica:">
													<ValidationProvider
														name="Enf.Reumatica"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="data.palpation_reumatica"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Sitio:">
													<ValidationProvider
														name="Sitio"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="data.palpation_sitio"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Tiempo:">
													<ValidationProvider
														name="Tiempo"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="data.palpation_tiempo"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Movilidad:">
													<ValidationProvider
														name="Movilidad"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="data.palpation_movilidad"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Hipotonia:">
													<ValidationProvider
														name="Hipotonia"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="data.palpation_hipotonia"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Atrofia:">
													<ValidationProvider
														name="Atrofia"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="data.palpation_atrofia"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group class="col-md-6" label="Dolor:">
													<ValidationProvider
														name="Dolor"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
															v-model="data.palpation_dolor"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<div class="col-md-12">
													<p>VALORACION MUSCULAR:</p>
												</div>
												<b-form-group
												class="col-md-6"
												label-for="fuerza"
												label="Fuerza:"
												>
													<b-form-select
														v-model="data.force"
														plain
														:options="['0', '1', '2', '3', '4', '5']"
														id="fuerza"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
													class="col-md-4"
													label="Contractura:"
													label-class="d-block"
												>
													<b-form-radio
														inline
														v-model="data.contracture"
														value="No"
													>
														No
													</b-form-radio>
													<b-form-radio 
														inline 
														v-model="data.contracture" 
														value="Si"
													>
														Si
													</b-form-radio>
												</b-form-group>
												<b-form-group class="col-md-6" label="Sitio:">
													<ValidationProvider
														name="Sitio"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-input
														v-model="data.site"
														type="text"
														:class="errors.length > 0 ? ' is-invalid' : ''"
														></b-form-input>
														<div class="invalid-feedback">
														<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
												<b-form-group
												class="col-md-6"
												label-for="flexion"
												label="Flexion Cadera:"
												>
													<b-form-select
														v-model="data.hip_flexion"
														plain
														:options="['Izquierda', 'Derecha', 'Ninguno']"
														id="contractura"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
												class="col-md-6"
												label-for="flexion_rodilla"
												label="Flexion Rodilla:"
												>
													<b-form-select
														v-model="data.knee_flexion"
														plain
														:options="['Izquierda', 'Derecha', 'Ninguno']"
														id="contractura"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
												class="col-md-6"
												label-for="flexion_tobillo"
												label="Flexion Tobillo:"
												>
													<b-form-select
														v-model="data.ankle_flexion"
														plain
														:options="['Izquierda', 'Derecha', 'Ninguno']"
														id="contractura"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
												class="col-md-6"
												label-for="extension_cadera"
												label="Extension Cadera:"
												>
													<b-form-select
														v-model="data.hip_extension"
														plain
														:options="['Izquierda', 'Derecha', 'Ninguno']"
														id="contractura"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
												class="col-md-6"
												label-for="extension_rodilla"
												label="Extension Rodilla:"
												>
													<b-form-select
														v-model="data.knee_extension"
														plain
														:options="['Izquierda', 'Derecha', 'Ninguno']"
														id="contractura"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
												class="col-md-6"
												label-for="extension_tobillo"
												label="Extension Tobillo:"
												>
													<b-form-select
														v-model="data.ankle_extension"
														plain
														:options="['Izquierda', 'Derecha', 'Ninguno']"
														id="contractura"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
												class="col-md-6"
												label-for="aduccion_tobillo"
												label="Aducción Cadera:"
												>
													<b-form-select
														v-model="data.hip_adduction"
														plain
														:options="['Izquierda', 'Derecha', 'Ninguno']"
														id="contractura"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
												class="col-md-6"
												label-for="aducction_rodilla"
												label="Aducción Rodilla:"
												>
													<b-form-select
														v-model="data.knee_adduction"
														plain
														:options="['Izquierda', 'Derecha', 'Ninguno']"
														id="contractura"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
												class="col-md-6"
												label-for="extension_tobillo"
												label="Aducción Tobillo:"
												>
													<b-form-select
														v-model="data.ankle_adduction"
														plain
														:options="['Izquierda', 'Derecha', 'Ninguno']"
														id="contractura"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
												class="col-md-6"
												label-for="abduccion_tobillo"
												label="Abducción Cadera:"
												>
													<b-form-select
														v-model="data.hip_abduction"
														plain
														:options="['Izquierda', 'Derecha', 'Ninguno']"
														id="contractura"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
												class="col-md-6"
												label-for="abducction_rodilla"
												label="Abducción Rodilla:"
												>
													<b-form-select
														v-model="data.knee_abduction"
														plain
														:options="['Izquierda', 'Derecha', 'Ninguno']"
														id="contractura"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
												class="col-md-6"
												label-for="extension_tobillo"
												label="Abducción Tobillo:"
												>
													<b-form-select
														v-model="data.ankle_abduction"
														plain
														:options="['Izquierda', 'Derecha', 'Ninguno']"
														id="contractura"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
												class="col-md-6"
												label-for="external_rotation_cadera"
												label="Rotación Externa Cadera:"
												>
													<b-form-select
														v-model="data.hip_external_rotation"
														plain
														:options="['Izquierda', 'Derecha', 'Ninguno']"
														id="contractura"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
													class="col-md-6"
													label-for="external_rotation_rodilla"
													label="Rotación Externa Rodilla:"
												>
													<b-form-select
														v-model="data.knee_external_rotation"
														plain
														:options="['Izquierda', 'Derecha', 'Ninguno']"
														id="contractura"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
												class="col-md-6"
												label-for="external_rotation_tobillo"
												label="Rotación Externa Tobillo:"
												>
													<b-form-select
														v-model="data.ankle_external_rotation"
														plain
														:options="['Izquierda', 'Derecha', 'Ninguno']"
														id="contractura"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
												class="col-md-6"
												label-for="internal_rotation_cadera"
												label="Rotación Interna Cadera:"
												>
													<b-form-select
														v-model="data.hip_internal_rotation"
														plain
														:options="['Izquierda', 'Derecha', 'Ninguno']"
														id="contractura"
													>
													</b-form-select>
												</b-form-group><b-form-group
												class="col-md-6"
												label-for="internal_rotation_rodilla"
												label="Rotación Interna Rodilla:"
												>
													<b-form-select
														v-model="data.knee_internal_rotation"
														plain
														:options="['Izquierda', 'Derecha', 'Ninguno']"
														id="contractura"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
												class="col-md-6"
												label-for="external_rotation_tobillo"
												label="Rotación Interna Tobillo:"
												>
													<b-form-select
														v-model="data.ankle_internal_rotation"
														plain
														:options="['Izquierda', 'Derecha', 'Ninguno']"
														id="contractura"
													>
													</b-form-select>
												</b-form-group>

												<div class="col-md-12">
													<p>Marcha:</p>
												</div>
												<b-form-group class="col-md-6" label="MARCHA:">
												<ValidationProvider
													name="Marcha"
													rules="required"
													v-slot="{ errors }"
												>
													<v-select
													:options="[
														'Libre',
														'Claudicante',
														'Con Ayuda',
														'Espástica',
														'Atáxica',
													]"
													v-model="data.march"
													:class="errors.length > 0 ? ' is-invalid' : ''"
													multiple
													/>
													<div class="invalid-feedback">
													<span>{{ errors[0] }}</span>
													</div>
												</ValidationProvider>
												</b-form-group>
												<b-form-group
												class="col-md-6"
												label-for="traslado"
												label="Traslado:"
												>
													<b-form-select
														v-model="data.transfer"
														plain
														:options="['Independiente', 'Con Ayuda', 'Sillas de ruedas']"
														id="contractura"
													>
													</b-form-select>
												</b-form-group>
												<b-form-group
												class="col-md-6"
												label-for="equilibrio"
												label="PROPIOCEPCIÓN Y EQUILIBRIO:"
												>
													<b-form-select
														v-model="data.balance"
														plain
														:options="['Presente', 'Ausente', 'Torpe']"
														id="contractura"
													>
													</b-form-select>
												</b-form-group>
												<div class="col-md-12">
												<p>Dolor:</p>
												</div>
													<b-form-group
													class="col-md-6"
													label-for="escala"
													label="Escala Analógica:"
													>
													<b-form-select
														v-model="data.analog_scale"
														plain
														:options="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']"
														id="contractura"
													>
													</b-form-select>
												</b-form-group>

												<b-form-group class="col-md-12" label="Diagnostico:">
													<ValidationProvider
														name="Diagnostico"
														rules="required"
														v-slot="{ errors }"
													>
														<b-form-textarea
															v-model="data.physiotherapeutic_diagnosis"
															type="text"
															:class="errors.length > 0 ? ' is-invalid' : ''"
														>
														</b-form-textarea>
														<div class="invalid-feedback">
															<span>{{ errors[0] }}</span>
														</div>
													</ValidationProvider>
												</b-form-group>
											</b-row>
											<b-button type="submit" variant="primary" class="mr-2">
												Guardar
											</b-button>
											<b-button type="reset" variant="none" class="iq-bg-danger">
												Cancelar
											</b-button>
										</b-form>
									</ValidationObserver>
								</template>
							</iq-card>
						</tab-content-item>
					</tab-content>
				</div>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
import { xray } from "../../../config/pluginInit";
import { Toast } from "../../../mixins/toast";
import api from "../../../services/api";
import Provinces from "../../../FackApi/json/Provinces.json";
import Swal from "sweetalert2";

export default {
	async mounted() {
		xray.index();
		await this.getData();
		this.getProvinces();
		await this.getDoctors();
	},
	data() {
		return {
			userForm: {},
			userExtraData: {},
			userFisio: {},
			currentPassword: "",
			countries: [
				{ value: "Canada", text: "Canada" },
				{ value: "Niada", text: "Niada" },
				{ value: "USA", text: "USA" },
				{ value: "India", text: "India" },
				{ value: "Africa", text: "Africa" },
			],
			states: [
				{ value: "California", text: "California" },
				{ value: "Florida", text: "Florida" },
				{ value: "Georgia", text: "Georgia" },
				{ value: "Connecticut", text: "Connecticut" },
				{ value: "Louisiana", text: "Louisiana" },
			],
			provinces: [],
			cantones: [],
			selectedProvince: "",
			storeOrUpdate: "store",
			obesityType: null,
			icon: null,
			color: null,
			bloodPressureClass: null,
			messageObesity: null,
			messagePressure: null,
			iconPressure: null,
			result: {},
			results: [],
			diagnosis: {},
			diagnosses: [],
			images: [],
			imagesFisio: [],
			data: {},
			files: [],
			doctors: []
		};
	},
	methods: {
		handleAddResult(e) {
			e.preventDefault();
			this.results = [...this.results, this.result]
			this.images = []
			this.result = {}
		},
		handleDeleteResult(local_result) {
			this.results = this.results.filter(result => result.result != local_result)
		},
		handleAddDiagnosis(e) {
			e.preventDefault();
			this.diagnosses = [...this.diagnosses, this.diagnosis]
			this.diagnosis = {}
		},
		handleDeleteDiagnosis(cie) {
			this.diagnosses = this.diagnosses.filter(result => result.cie != cie)
		},
		previewImages(event) {
			const files = event.target.files
			this.result.images = files
			if(files && files.length > 0) {
				this.images = [];
				Array.from(files).forEach(file => {
					const lector = new FileReader();
					lector.onload = (e) => {
						this.images.push(e.target.result)
					}
					lector.readAsDataURL(file)
				})
			}
		},
		async getDoctors() {
			try {
				const { data } = await api.get(
					'/doctors'
				)
				this.doctors = [...data.data.map(item => {
					return {
						value: item.id,
						text: item.name
					}
				})];
				console.log(this.doctors)
			} catch(e) {
				console.log(e)
			}
		},
		async getData() {
			const user = JSON.parse(localStorage.getItem("user"));
			const userEdit = localStorage.getItem("userEdit");
			try {
				const { data } = await api.get(`/patients/${user.patient_id ?? userEdit}`);
				this.userForm = data.data;
				this.userExtraData = data.data.extra_data ? data.data.extra_data : {};
				this.results = data.data.extra_data.exam_results ?? []
				this.diagnosses = data.data.extra_data.diagnoses ?? []
				this.storeOrUpdate = data.data.extra_data ? "update" : "store";
				this.data = data.data.fisio ?? {}
				this.imagesFisio = data.data.fisio.images ?? []
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
		previewImagesFisio(event) {
			const files = event.target.files
			this.files = files

			if(files && files.length > 0) {
				this.images = [];
				Array.from(files).forEach(file => {
					const lector = new FileReader();
					lector.onload = (e) => {
						this.imagesFisio.push(e.target.result)
					}
					lector.readAsDataURL(file)
				})
			}
		},
		async uploadPhoto(file) {
			const user = JSON.parse(localStorage.getItem("user"));
			try {
				const formData = new FormData();
				formData.append("photo", file);

				await api.post(`/patients/${user.patient_id}/photo`, formData);
				await this.getData();

				Toast.fire({
					icon: "success",
					title: "Foto Actualizada",
				});
			} catch (error) {
				const errorMessage =
					error?.response?.data?.message || "Error al actualizar la configuración";
				Toast.fire({
					icon: "error",
					title: errorMessage,
				});
			}
		},
		getProvinces() {
			for (let key in Provinces) {
				if (Provinces.hasOwnProperty(key)) {
					if (Provinces[key].provincia) {
						const provincia = Provinces[key].provincia;
						this.provinces.push({
							id: key,
							name: provincia,
						});
					}
				}
			}
		},
		getCantones() {
			const data = Provinces[this.selectedProvince];
			this.userForm.province = data.provincia;
			this.cantones = Object.values(data.cantones);
		},
		showFirstAlert() {
			this.calculateObesityType();

			Swal.fire({
				title: this.obesityType,
				text: this.messageObesity,
				icon: this.icon,
				iconColor: this.color,
				showCancelButton: false,
				showConfirmButton: true,
				preConfirm: () => {
					this.showSecondAlert();
				},
			});
		},
		showSecondAlert() {
			this.checkBloodPressure();
			Swal.fire({
				title: this.bloodPressureClass,
				text: this.messagePressure,
				icon: this.iconPressure,
				showCancelButton: false,
				showConfirmButton: true,
			});
		},
		calculateObesityType() {
			const bmi = this.userExtraData.weight / (this.userExtraData.height / 100) ** 2; // Cálculo del IMC

			if (bmi < 18.5) {
				this.obesityType = "Bajo peso";
				this.color = "#1d90cf";
				this.icon = "warning";
				this.messageObesity = "¡Estás en buen tiempo para mejorar! 🙂";
			} else if (bmi >= 18.5 && bmi < 24.9) {
				this.obesityType = "Peso normal";
				this.color = "#5bb5e9";
				this.icon = "success";
				this.messageObesity = "¡Excelente sigue así! 😁";
			} else if (bmi >= 25 && bmi < 29.9) {
				this.obesityType = "Sobrepeso";
				this.color = "#30a149";
				this.icon = "warning";
				this.messageObesity = "¡Estás en buen tiempo para mejorar! 🙂";
			} else if (bmi >= 30 && bmi < 34.9) {
				this.obesityType = "Obesidad Tipo I";
				this.color = "#f4cc36";
				this.icon = "error";
				this.messageObesity = "¡Atención, debes tener mucho cuidado! 😔";
			} else if (bmi >= 35 && bmi < 39.9) {
				this.obesityType = "Obesidad Tipo II";
				this.color = "#f7a617";
				this.icon = "error";
				this.messageObesity = "¡Atención, debes tomar medidas urgentes! 😪";
			} else {
				this.obesityType = "Obesidad Tipo III";
				this.color = "#d3222a";
				this.icon = "error";
				this.messageObesity = "¡Atención, debes tomar medidas urgentes! 😪";
			}
		},
		checkBloodPressure() {
			const systolic = this.userExtraData.systolic_pressure;
			const diastolic = this.userExtraData.diastolic_pressure;

			if (systolic < 120 || diastolic < 80) {
				this.bloodPressureClass = "Normal";
				this.messagePressure = "¡Estás en buen tiempo para mejorar! 🙂";
				this.iconPressure = "warning";
			} else if (systolic >= 120 && systolic < 139 && diastolic >= 80 && diastolic < 89) {
				this.bloodPressureClass = "Prehipertensión";
				this.messagePressure = "¡Excelente sigue así! 😁";
				this.iconPressure = "success";
			} else if (systolic >= 140 && systolic < 159 && diastolic >= 90 && diastolic < 99) {
				this.bloodPressureClass = "Hipertensión Etapa 1";
				this.messagePressure = "¡Estás en buen tiempo para mejorar! 🙂";
				this.iconPressure = "warning";
			} else if (systolic >= 160 || diastolic >= 100) {
				this.bloodPressureClass = "Hipertensión Etapa 2";
				this.messagePressure = "¡Atención, debes tomar medidas urgentes! 😪";
				this.iconPressure = "error";
			}
		},
		async onSubmit() {
			const user = JSON.parse(localStorage.getItem("user"));
			const url = `/patients/${user.patient_id}`;

			try {
				await api.put(url, this.userForm);
				await this.getData();

				Toast.fire({
					icon: "success",
					title: "Actualizado Correctamente",
				});
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
		async onSubmitFisio() {
			const user = JSON.parse(localStorage.getItem('user'));
			const url = `/patients/${user.patient_id}/fisio`;

			const formData = new FormData()

			for(let i = 0; i < this.files.length; i++) {
				formData.append('images[]', this.files[i]);
			}

			let response;
			console.log(this.data)
			try {
				response = await api.put(url, this.data);

				if(response) {
					await api.post(`/patients/${user.patient_id}/fisio/images`, formData);
				}
				await this.getData();

				Toast.fire({
					icon: "success",
					title: "Historial Fisioterapeutico Actualizado"
				});
			} catch (e) {
				Toast.fire({
					icon: "error",
					title: "Error"
				})
			}
		},
		async onSubmitExtraData() {
			const user = JSON.parse(localStorage.getItem("user"));
			const url = `/patients/${user.patient_id}/extra`;
			const formData = new FormData();
			this.results.forEach((item, index) => {
				formData.append(`results[${index}][name]`, item.name);
				formData.append(`results[${index}][date]`, item.date);
				formData.append(`results[${index}][result]`, item.result);

				for(let i = 0; i < item.images.length; i++) {
					formData.append(`results[${index}][images][]`, item.images[i])
				}
			})

			let response;
			this.userExtraData = {
				...this.userExtraData,
				exam_results: [],
				diagnoses: this.diagnosses,
			}
			try {
				if (this.storeOrUpdate === "store") {
					response = await api.post(url, this.userExtraData);
				} else {
					response = await api.put(url, this.userExtraData);
				}
				if(response) {
					await api.post(`/patients/${user.patient_id}/history/images`, formData);
				}
				this.showFirstAlert();
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
	},
};
</script>
